import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router } from '@angular/router';

// export const msdf = new SimpleDateFormat("");

import firebase from 'firebase/compat/app';
import '@firebase/auth';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { finalize, first, take } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any; // Save logged in user data
  socialUser: any;

  constructor(
    private translateService: TranslateService,
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning,
    private toastr: ToastrService,
    private afStorage: AngularFireStorage,
    private _loader: NgxUiLoaderService
  ) {
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user: any) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
      } else {
        localStorage.setItem('user', '');
      }
    });
  }

  // Sign in with email/password
  SignIn(email: any, password: any) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result: any) => {
        this.GetUserData(result.user.uid).subscribe((p: any) => {
          this._loader.stop();
          if (p.data().completed) {
            this.router.navigate(['/dashboard', 'home']);
          } else {
            this.router.navigate(['/registration']);
          }
        });
      })
      .catch((error: any) => {
        this._loader.stop();

        this.toastr.error(
          this.translateService.instant(error.code),
          this.translateService.instant('Errore')
        );
      });
  }

  // Sign up with email/password
  SignUp(email: any, password: any) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result: any) => {
        /* Call the SendVerificaitonMail() function when new user sign 
      up and returns promise */
        this.SetUserData(result.user);
        this.ngZone.run(() => {
          this._loader.stop();

          this.router.navigate(['/registration']);
        });
      })
      .catch((error: any) => {
        var errorCode = error.code;
        if (errorCode == 'auth/email-already-in-use') {
          this.SignIn(email, password);
        } else {
          this._loader.stop();

          this.toastr.error(
            this.translateService.instant(error.code),
            this.translateService.instant('Errore')
          );
        }
      });
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `profile/${user.uid}`
    );

    const userData = {
      nome: user.nome || null,
      cognome: user.cognome || null,
      dataNascita: null,
      sesso: null,
      dispositivi: null,
      fasce: [
        {
          value: '0.0',
        },
        {
          value: '2.0',
        },
        {
          value: '4.0',
        },
        {
          value: '6.0',
        },
        {
          value: '8.0',
        },
        {
          value: '10.0',
        },
      ],
      completed: false,
      altezza: null,
      peso: null,
      fcMax: null,
      foto: user.foto || null,
      impostazioni: null,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  async uploadUserPhoto(userImg: any) {
    const file = userImg;
    const filePath = userImg.name;
    const fileRef = this.afStorage.ref(filePath);
    const task = this.afStorage.upload(filePath, file);
    return await (await task).ref.getDownloadURL();
  }

  async updateProfile(info: any) {
    let fotoUrl;

    if (info.foto.avatar && typeof info.foto.avatar != 'string') {
      fotoUrl = await this.uploadUserPhoto(info.foto.avatar);
    } else if (typeof info.foto.avatar == 'string') {
      fotoUrl = info.foto.avatar;
    } else {
      fotoUrl = null;
    }

    let data_nascita = formatDate(
      info.informazioni.data_nascita,
      'dd/MM/yyyy',
      'it-IT'
    );
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      'profile/' + this.userData.uid
    );

    const userData = {
      nome: info.informazioni.nome || null,
      cognome: info.informazioni.cognome || null,
      dataNascita: data_nascita || null,
      sesso:
        info.informazioni.sesso === undefined ? null : info.informazioni.sesso,
      altezza: info.atleta.altezza.toString() || null,
      peso: info.atleta.peso.toString() || null,
      fcMax: info.atleta.fc_max.toString() || null,
      foto: fotoUrl,
      dispositivi: null,
      completed: true,
      impostazioni: info.atleta.impostazioni,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  async updateSpeed(info: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      'profile/' + this.userData.uid
    );

    const userData = {
      fasce: [
        {
          value: info.f_0,
        },
        {
          value: info.f_1,
        },
        {
          value: info.f_2,
        },
        {
          value: info.f_3,
        },
        {
          value: info.f_4,
        },
        {
          value: info.f_5,
        },
      ],
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  GetUserData(uid: any) {
    let user = JSON.parse(localStorage.getItem('user')!);
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `profile/${user ? user.uid : uid}`
    );

    return userRef.get().pipe(first());
  }

  watchUserData() {
    let user = JSON.parse(localStorage.getItem('user')!);
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `profile/${user.uid}`
    );

    return userRef.valueChanges();
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null ? true : false;
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['/']);
    });
  }

  loginWithGoogle() {
    return this.afAuth
      .signInWithPopup(new firebase.auth!.GoogleAuthProvider())
      .then((result: any) => {
        this.afs
          .collection('profile')
          .doc(result.user!['uid'])
          .get()
          .subscribe(async (u: any) => {
            if (!u.exists) {
              let user = {
                uid: result.user!['uid'],
                nome: result.additionalUserInfo.profile['given_name'],
                cognome: result.additionalUserInfo.profile['family_name'],
                foto: result.additionalUserInfo.profile['picture'],
                completed: false,
              };

              await this.afs.collection('profile').doc(user.uid).set({});
              this.SetUserData(user);
              this.setSocialUser(user);
              this.router.navigate(['/registration']);
            } else {
              this.router.navigate(['/dashboard', 'home']);
            }
          });
      });
  }

  // Sign in with Facebook
  FacebookAuth() {
    return this.AuthLogin(new firebase.auth!.FacebookAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result: any) => {
        this.ngZone.run(async () => {
          if (result.additionalUserInfo!.isNewUser) {
            let user = {
              uid: result!.user!['uid'],
              nome: result.additionalUserInfo.profile['first_name'],
              cognome: result.additionalUserInfo.profile['last_name'],
              foto: result.additionalUserInfo.profile['picture']['data']['url'],
              completed: false,
            };

            await this.afs.collection('profile').doc(user.uid).set({});
            this.SetUserData(user);
            this.setSocialUser(user);
            this.router.navigate(['/registration']);
          } else {
            this.GetUserData(result.user.uid).subscribe((p: any) => {
              if (p.data().completed) {
                this.router.navigate(['/dashboard', 'home']);
              } else {
                this.router.navigate(['/registration']);
              }
            });
          }
        });
      })
      .catch((error: any) => {
        this.toastr.error(
          this.translateService.instant(error.code),
          this.translateService.instant('Errore')
        );
      });
  }

  setSocialUser(user: any) {
    this.socialUser = user;
  }

  getSocialUser() {
    return this.socialUser;
  }
}
function downloadURL<T>(downloadURL: any) {
  throw new Error('Function not implemented.');
}
