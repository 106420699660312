import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule, FORMLY_CONFIG } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { CommonModule } from '@angular/common';
import { FormlyWrapperAddons } from './_core/_formly/addons.wrapper';
import { addonsExtension } from './_core/_formly/addons.extension';
import { MatIconModule } from '@angular/material/icon';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { FormlyFieldStepper } from './_core/_formly/stepper/stepper.component';

import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { FormlyFieldChips } from './_core/_formly/chips/chips.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormlyFieldChipsWrapper } from './_core/_formly/chips/chips-wrapper/chips-wrapper.component';
import { FormlyFieldAvatar } from './_core/_formly/avatar/avatar.component';
import { FormlyFieldAvatarWrapper } from './_core/_formly/avatar/avatar-wrapper/avatar-wrapper.component';
import { FileValueAccessor } from './_core/_formly/avatar/file.value-accessor';
import { FormlyFieldTextareaWrapper } from './_core/_formly/textarea/textarea-wrapper/textarea-wrapper.component';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { FormlyMatSliderModule } from '@ngx-formly/material/slider';
import { AuthService } from './_core/_service/auth.service';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { MatOptionModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, SPINNER,  POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { FormlyFieldToggleWrapper } from './_core/_formly/toggle/toggle-wrapper/toggle-wrapper.component';
import { FormlyFieldToggle } from './_core/_formly/toggle/toggle.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormlyFieldAutoComplete } from './_core/_formly/autocomplete/autocomplete.component';
import {DragDropModule} from '@angular/cdk/drag-drop';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import { AgmCoreModule } from '@agm/core';
import { NgChartsModule } from 'ng2-charts';
import { FormlyFieldMultipleChips } from './_core/_formly/multiple-chips/multiple-chips.component';


registerLocaleData(localeIt, 'it');

const ngxUiLoaderConfig: NgxUiLoaderConfig= {
  "fgsColor": "#ff8000",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "square-jelly-box",
  "gap": 24,
  "logoPosition": "center-center",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "#ff8000",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
}

export function formlyValidationConfig(translate: TranslateService) {
  return {
    validationMessages: [
      {
        name: 'required',
        message() {
          return translate.stream('FORM.VALIDATION.REQUIRED');
        },
      },
    ],
  };
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    FormlyWrapperAddons,
    FormlyFieldStepper,
    FormlyFieldChips,
    FormlyFieldChipsWrapper,
    FormlyFieldAvatar,
    FormlyFieldAvatarWrapper,
    FileValueAccessor,
    FormlyFieldTextareaWrapper,
    FormlyFieldToggleWrapper,
    FormlyFieldToggle,
    FormlyFieldAutoComplete,
    FormlyFieldMultipleChips,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    AngularFireStorageModule,
    AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground:true}),
    ToastrModule.forRoot({
      timeOut: 5 * 1000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true
    }),
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    MatInputModule,
    MatAutocompleteModule,
    NgChartsModule.forRoot(),
    FormlyModule.forRoot({ 
      extras: { lazyRender: true },
      types: [
        { name: 'stepper', component: FormlyFieldStepper },
        { name: 'input-chips', component: FormlyFieldChips, wrappers: ['input-chips-wrapper'], },
        { name: 'multiple-chips', component: FormlyFieldMultipleChips, wrappers: ['input-chips-wrapper'], },
        { name: 'avatar-upload', component: FormlyFieldAvatar, wrappers: ['avatar-upload-wrapper'], },
        { name: 'custom-toggle', component: FormlyFieldToggle, wrappers: ['form-field'], },
        { name: 'autocomplete', component: FormlyFieldAutoComplete, wrappers: ['form-field'], },
      ],
      wrappers: [
        { name: 'addons', component: FormlyWrapperAddons },
        { name: 'input-chips-wrapper', component: FormlyFieldChipsWrapper },
        { name: 'avatar-upload-wrapper', component: FormlyFieldAvatarWrapper },
        {name: 'textarea-wrapper', component: FormlyFieldTextareaWrapper},
        {name: 'toggle-wrapper', component: FormlyFieldToggleWrapper}
      ],
      extensions: [
        { name: 'addons', extension: { onPopulate: addonsExtension } },
      ],
    }),
    MatSlideToggleModule,
    FormlyMaterialModule,
    FormlyMatSliderModule,
    FormlyMatToggleModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    MatStepperModule,
    DragDropModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDfKHqnagHlYRonWVMHxV27VPkWryLXoic',
      libraries: ['places', 'drawing', 'geometry'],
    }),

  ],
  providers: [
    AuthService,
    { provide: FORMLY_CONFIG, multi: true, useFactory: formlyValidationConfig, deps: [TranslateService] },
    { provide: MESSAGE_FORMAT_CONFIG, useValue: { multi:true, locales: ['it'] }},
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT'},

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
