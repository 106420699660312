import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'multiple-chips',
  templateUrl: './multiple-chips.component.html',
  styleUrls: ['./multiple-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class FormlyFieldMultipleChips extends FieldType  {
  isSelected(value:any){
    return this.formControl.value ? this.formControl.value.indexOf(value) !== -1 : false;
  }

  toggle(value:any){
    let array = this.formControl.value;
    if(!array)
    array = [];

    var index = array.indexOf(value);

    if (index === -1) {
        if(array.length < 2)
        array.push(value);
    } else {
        array.splice(index, 1);
    }

    this.formControl.setValue(array)
  }

}
