export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCryliNCEHNIPXPsq1Ccm6AAPL23LKlsEM",
    authDomain: "sprinto-560a6.firebaseapp.com",
    projectId: "sprinto-560a6",
    storageBucket: "sprinto-560a6.appspot.com",
    messagingSenderId: "896764896164",
    appId: "1:896764896164:web:b14626d407bd74be1bd731"
  }
};

// export const environment = {
//   production: true,
//   firebase: {
//     apiKey: "AIzaSyDSO-5D45jWhk2ctC0relW99v8Qi5MeW94",
//     authDomain: "sprinto-6586f.firebaseapp.com",
//     projectId: "sprinto-6586f",
//     storageBucket: "sprinto-6586f.appspot.com",
//     messagingSenderId: "762730906256",
//     appId: "1:762730906256:web:f53c3365ff1073b4498e15",
//     measurementId: "G-J46Z1GGVBV"
//   }
// };

