import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-chips-wrapper',
  templateUrl: './chips-wrapper.component.html',
  styleUrls: ['./chips-wrapper.component.scss']
})
export class FormlyFieldChipsWrapper  extends FieldWrapper {
}
