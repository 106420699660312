import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'avatar-upload',
  templateUrl: './avatar-wrapper.component.html',
  styleUrls: ['./avatar-wrapper.component.scss']
})
export class FormlyFieldAvatarWrapper extends FieldWrapper{
}
