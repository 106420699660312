import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { MatInput } from '@angular/material/input';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'formly-autocomplete',
  template: `
  <input matInput
  [matAutocomplete]="auto"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [placeholder]="to.placeholder"
  [errorStateMatcher]="errorStateMatcher">
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="to.onValueSelect(field,$event)" (opened)="autocompleteScroll()" [displayWith]="getOptionText">
  <mat-option *ngFor="let value of filter" [value]="value">
    {{ value.label }}
  </mat-option>
</mat-autocomplete>
  `,
})
export class FormlyFieldAutoComplete extends FieldType implements OnInit, AfterViewInit {
  @ViewChild(MatInput) formFieldControl!: MatInput;
  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  @ViewChild('auto') autoRef!: MatAutocomplete;

  subscription: Subscription = new Subscription();

  filter: any[]= [];

  step = 10;
  term = ""
  ngOnInit() {
    super.ngOnInit();
    this.filter = this.to.filter('').slice(0, this.step);
    this.formControl.valueChanges.subscribe(term => {

      this.term = term
      this.filter = this.to.filter(term).slice(0, this.step);
    })

  }

  getOptionText(option:any) {
    if(option)
      return option.label;
    else
      return "";
  }



  ngAfterViewInit() {
    super.ngAfterViewInit();
    this._subscribeToClosingActions();
    // temporary fix for https://github.com/angular/material2/issues/6728
    (<any> this.autocomplete)._formField = this.formField;
  }

  private _subscribeToClosingActions(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.autocomplete.panelClosingActions
      .subscribe(e => {
        if (!e || !e.source) {
          if(this.filter.length == 1){
            this.formControl.setValue(this.filter[0]);
          }else{
            this.formControl.setValue('');

          }
        }
      },
      err => this._subscribeToClosingActions(),
      () => this._subscribeToClosingActions());
  }

  autocompleteScroll() {
    setTimeout(() => {
      if (
        this.autoRef &&
        this.autocomplete &&
        this.autoRef.panel
      ) {
        fromEvent(this.autoRef.panel.nativeElement, 'scroll')
          .pipe(
            map(x => this.autoRef.panel.nativeElement.scrollTop),
            takeUntil(this.autocomplete.panelClosingActions)
          )
          .subscribe(x => {
            const scrollTop = this.autoRef.panel.nativeElement
              .scrollTop;
            const scrollHeight = this.autoRef.panel.nativeElement
              .scrollHeight;
            const elementHeight = this.autoRef.panel.nativeElement
              .clientHeight;
              const atBottom = scrollHeight === scrollTop + elementHeight;
            if (atBottom) {
              this.filter = [...this.filter, ...this.to.filter(this.term).slice(this.filter.length, this.filter.length+this.step)];
            }
          });
      }
    });
  }
  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
