
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'input-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldChips extends FieldType {
  selected = new BehaviorSubject(null);

  ngOnInit(){
    this.formControl.valueChanges.subscribe(value => {
      this.selected.next(value);
    })
  }

  toggle(choice: any): void {
    this.formControl.setValue(choice);
  }
}
