import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-avatar-upload',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class FormlyFieldAvatar extends FieldType {

  imageUrl:BehaviorSubject<any> = new BehaviorSubject(null);
  selectedImg: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);


  ngOnInit(): void {
    this.formControl.valueChanges.subscribe(data =>{
      if(data && typeof(data)=='string'){
        this.imageUrl.next(data)
        this.selectedImg.next(true)
      }
      
    })
  }


  uploadFile(event:any) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      
      this.selectedImg.next(true);
      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl.next(reader.result);
        this.value=file
      };

    }
  }


}
