import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'sprinto';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('it');

    this.matIconRegistry.addSvgIcon(
      'user_profile',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/user-profile.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'sprinto',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/sprinto.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'speed',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/speed.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'plan',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/plan.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'results',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/results.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'categories',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/categorie.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'workout',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/workout.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'rest',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/rest.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'add',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/add.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'vibration',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/vibration.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'alert',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/alert.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'watch-button',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/watch-button.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'drag-drop',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/drag-drop.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'remove',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/remove.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'copy',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/copy.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'end-work',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/end.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'avvisoStart',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/avvisoStart.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'categorie1',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/categorie1.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'stats',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/stats.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'stats',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/stats.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'location',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/location.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'bpm',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/bpm.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'table',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/table.svg'
      )
    );
  }
}
