import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-toggle-wrapper',
  templateUrl: './toggle-wrapper.component.html',
  styleUrls: ['./toggle-wrapper.component.scss']
})
export class FormlyFieldToggleWrapper extends FieldWrapper {


}
